import React, { Component } from 'react';
import { Router, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { withLocalize, initialize  } from 'react-localize-redux';
import 'moment/locale/fr';
import Loadable from 'react-loadable';
import './App.scss';

// Custom components or lib functions
import { availableLanguages, fallbackLocale } from './_config';
import { history } from './_helpers';
import { layoutActions, translateActions } from './_services/actions';
import { DefaultRoute } from './_components/layout';


// routes config
import routes from './routes';

// Containers
import { DefaultLayout, FullPageLayout } from './containers';

// Pages
const loading = () => <div className="animated fadeIn pt-3 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>;

const Login = Loadable({
  loader: () => import('./_pages/Auth/Login'),
  loading
});
const ForgotPwd = Loadable({
  loader: () => import('./_pages/Auth/ForgotPwd'),
  loading
});
const ResetPwd = Loadable({
  loader: () => import('./_pages/Auth/ResetPwd'),
  loading
});
const ActivateAccount = Loadable({
  loader: () => import('./_pages/Auth/ActivateAccount'),
  loading
});
const Page404 = Loadable({
  loader: () => import('./_pages/Page404'),
  loading
});

class App extends Component {
  constructor(props) {
    super(props);
    this.mounted = true;
    this.state = {
        loaded: false
    };
    this.routeComponents = [];
    translateActions.getLocales(this.props.dispatch,this.onLocaleSuccess,this.onLocaleFailure);
  }
  componentWillUnmount=()=>{
    this.mounted = false;
  }
  onLocaleSuccess = ( response ) => {
    let availableLanguagesFromServer = [];
    response.data.map(function(locale,i){
      let sort_order = 1;
      if(locale === fallbackLocale){
        sort_order = 0;
      }
      let language = {'code':locale, 'sort_order':sort_order};
      availableLanguagesFromServer.push(language);
      return false;
    });

    availableLanguagesFromServer.sort(function(a, b){
       if (a['sort_order'] < b['sort_order'])
        return -1
      else if (a['sort_order'] > b['sort_order'])
        return 1
      else
        return 0
    });

    // translation
    const translationSettings = {
      languages : availableLanguagesFromServer,
      options: {
        renderInnerHtml: true,
        defaultLanguage: fallbackLocale,
        renderToStaticMarkup:false
      }
    };
    this.props.dispatch(initialize(translationSettings));
    this.props.dispatch(layoutActions.initBreadcrumb());
    if(this.mounted){
      this.setState({
        loaded:true
      });
    }
  }
  onLocaleFailure = (response = undefined ) => {
    availableLanguages.sort(function(a, b){
       if (a['sort_order'] < b['sort_order'])
        return -1
      else if (a['sort_order'] > b['sort_order'])
        return 1
      else
        return 0
    });
    // translation
    const translationSettings = {
      languages : availableLanguages,
      options: {
        renderInnerHtml: true,
        defaultLanguage: fallbackLocale,
        renderToStaticMarkup:false
      }
    };
    this.props.dispatch(initialize(translationSettings));
    this.props.dispatch(layoutActions.initBreadcrumb());
    if(this.mounted){
      this.setState({
        loaded:true
      });
    }
  }
  // To get the routes for the default layout (with left navigation)
  getDefaultLayoutRoutes = () => {
      let routeComponents = [];
      routes.map((route,index) =>{
          routeComponents.push(<DefaultLayout key={index} exact path={`/:lang?/${route.path}`} allowed_roles={route.allowed_roles} component={route.component} />)
          return true;
      });
      return routeComponents;
  };
  render() {
    const { loaded } = this.state
    return (
      <Router history={history}>
        {loaded &&
          <Switch>
            <FullPageLayout exact path={`/:lang?/account/login`} name="Login Page" component={Login} />
            <FullPageLayout exact path={`/:lang?/account/forgot-password`} name="Forgot password" component={ForgotPwd} />
            <FullPageLayout exact path={`/:lang?/account/reset-password`} name="Reset password" component={ResetPwd} />
            <FullPageLayout exact path={`/:lang?/account/activate`} name="Activate account" component={ActivateAccount} />
            <FullPageLayout exact path={`/:lang?/404`} name="404 page" component={Page404} />
            {this.getDefaultLayoutRoutes()}
            <DefaultRoute path="*" />
          </Switch>
        }
        </Router>
    );
  }
}

function mapStateToProps(state) {
    return {
        user:state.authentication.user
    };
}
const AppWithLocalize = withLocalize(App);
App = connect(mapStateToProps)(AppWithLocalize);
export default App;
