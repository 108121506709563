//Core react libs
import React, {Component} from 'react';
import { connect } from 'react-redux';

//HTML editor
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// Translation
import {getTranslate, getLanguages, getActiveLanguage } from 'react-localize-redux';

//UI components
import { FormGroup, Nav, NavItem, NavLink, TabContent, TabPane, Col } from 'reactstrap';
import classnames from 'classnames';

// Local custom components/libs
import { fallbackLocale } from '../../../_config';
import { langTabHasError } from '../../../_helpers';
import {
   InputTextFieldWithLabel
} from '../InputTextFieldWithLabel'


/** General information card **/
class FieldsWithTab extends Component {

  constructor(props) {
      super(props);

      // Initial state
      this.state = {
        activeTab:fallbackLocale,
      };
  }

  /**
  * To toggle the active states of the tabs
  * @param {string} tab
  * @returns  {boolean}
  */
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
    return true;
  }

  /**
  * When input fields value changes
  * @param {Object} event
  * @returns {boolean}
  */
  handleChange = (langCode, field, event) => {
      if(field.max_characters !== null){
        const {value} = event.target;
        if(value.length <= field.max_characters){
          this.props.handleChange(langCode, field.name, event);
          return true;
        }
        else{
          return false;
        }
      }
      else{
        this.props.handleChange(langCode, field.name, event);
        return true;
      }
  }

  /**
  * getFieldErrorMessages
  * To get the error messages for each field
  * @param [field]
  * @param  {Object}[errorBag] - error messages
  */
  getFieldErrorMessages =(field)=>{
    let httpErrorsExist = false;
    if(this.props.alert && this.props.alert.errors){
      let httpFieldErrors = this.props.alert.errors;
      if(httpFieldErrors && httpFieldErrors[field] && httpFieldErrors[field].length > 0){
        httpErrorsExist = true;
        return httpFieldErrors[field];
      }
      else
        httpErrorsExist = false;
    }
    if(!httpErrorsExist)
      return this.props.errorBag.field_validation_messages;
  }

  /**
  * To render the component
  * @returns  {Object} HTML element
  */
  render() {
    const { languages, translate, data, fields, errorBag } = this.props;
    const { activeTab } = this.state;
    return (
          <FormGroup row>
              <Col sm="12">
                <Nav tabs>
                  { // Tab headers
                  languages.map((lang,key) => {
                    return(
                      <NavItem key = {lang.code}>
                        <NavLink
                          className={classnames({ active: activeTab === lang.code })}
                          onClick={() => { this.toggle(lang.code); }}
                        >
                          {(langTabHasError(lang.code, fields, errorBag.field_validation_messages)) &&
                          <span className="text-danger"><i className="fal fa-exclamation-circle"></i> &nbsp;</span>
                          }
                          {translate(lang.code)}
                        </NavLink>
                      </NavItem>
                    )
                  })
                  }
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  {
                  languages.map((lang,key) => {
                    return(
                      <TabPane tabId={lang.code} key={lang.code}>
                        {
                          fields.map((field,fieldkey) => {
                            if(field.type === 'html')
                              return(
                                <ReactQuill
                                key={`${lang.code}${field}`}
                                value = {data.translations[lang.code][field.name]}
                                onChange = {(value) => this.handleChange(lang.code, field, value)}
                                />
                             )
                            else if(field.type === 'textarea')
                              return(
                                <div key={`${lang.code}${field}`}>
                                  <InputTextFieldWithLabel
                                  key = {`translation_${lang.code}_${field.name}`}
                                  type = "textarea"
                                  name = ""
                                  id = {`${field.prefix !== undefined?field.prefix:''}translation_${lang.code}_${field.name}`}
                                  rows="5"
                                  value = {data.translations[lang.code][field.name]}
                                  label={translate(field.name)}
                                  placeholder={translate("placeholder_"+field.name)}
                                  required={fallbackLocale === lang.code?field.required:false}
                                  onChange = {(e) => this.handleChange(lang.code, field, e)}
                                  errorMessages = {this.getFieldErrorMessages(`${field.prefix !== undefined?field.prefix:''}translation_${lang.code}_${field.name}`)}
                                  >
                                  <div className="text-right">{data.translations[lang.code][field.name].length} / {field.max_characters}</div>
                                  </InputTextFieldWithLabel>
                                </div>
                             )
                            else
                            return(
                              <InputTextFieldWithLabel
                              key = {`translation_${lang.code}_${field.name}`}
                              type = "text"
                              name = ""
                              id = {`${field.prefix !== undefined?field.prefix:''}translation_${lang.code}_${field.name}`}
                              value = {data.translations[lang.code][field.name]}
                              label={translate(field.name)}
                              placeholder={translate("placeholder_"+field.name)}
                              required={fallbackLocale === lang.code?field.required:false}
                              onChange = {(e) => this.handleChange(lang.code, field, e)}
                              errorMessages = {this.getFieldErrorMessages(`${field.prefix !== undefined?field.prefix:''}translation_${lang.code}_${field.name}`)}
                           />
                           )
                        })
                      }
                    </TabPane>
                   )
                 }) //Tab content languages loop ends
                 }
               </TabContent>
              </Col>
        </FormGroup>
    );
  }
}



function mapStateToProps(state) {
    return {
        translate: getTranslate(state.localize),
        languages: getLanguages(state.localize),
        activeLanguage: getActiveLanguage(state.localize).code,
        loading: state.http_request.sending,
        alert: state.http_request.response,
    };
}

FieldsWithTab = connect(mapStateToProps)(FieldsWithTab);
export {FieldsWithTab};
