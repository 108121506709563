
// To remove empty translation fields
export function getTranslationDataToSubmit(languages, fields, translations){
  let translationDataToSubmit = {};
  languages.map((language) => {
    translationDataToSubmit[language.code]={};
    fields.map((field)=>{
      if(translations[language.code][field.name] !== ''){
        translationDataToSubmit[language.code][field.name] = translations[language.code][field.name]
      }
      return true;
    });
    return true;
  });
  return translationDataToSubmit;
}
