/**
access: array
To define the access for the component blocks
**/


export const BLOCK_ACCESS_RULES = [
  { block_name: 'SYSTEM_NOTIFICATION_VIEW_USERSBLOCK', allowed_roles:['admin','sysadmin'] },
  { block_name: 'FORM_SLUG', allowed_roles:['sysadmin'] },
  { block_name: 'ITEM_SETTING', allowed_roles:['admin', 'sysadmin'] },
  { block_name: 'SYSTEM_NOTIFCATION_SEND_NOTIFICATION', allowed_roles:['admin','sysadmin'] },
  { block_name: 'ITEM_OWNER', allowed_roles:['admin','sysadmin','business_admin'] },
  { block_name: 'ITEM_REFRESH_CACHE', allowed_roles:['admin','sysadmin'] }

];
