/**
authActions
To dispatch the authentication actions
@functions:
logout,
*/

import { urlConfig, fallbackLocale } from '../../_config';
import { history, store } from '../../_helpers';
import { getActiveLanguage } from 'react-localize-redux';

export const authActions = {
    logout,
};


/**
* logout
* To dispatch the logout action
* @param []
* @param  {Object} reducer [reducer to logout]
*/
function logout() {
    const state = store.getState();
    const locale = (getActiveLanguage(state.localize))?getActiveLanguage(state.localize).code:fallbackLocale;
    localStorage.removeItem('user');
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('expires_in');
    history.push(urlConfig.BASE_URL+'/'+locale+'/account/login');
    return{ type: 'AUTH_LOGOUT' };
}
