export default {
  items: [
    {
      namekey: 'Dashboard',
      urlkey: '',
      icon: 'fas fa-home',
      isHomePage:true
    },
    {
      namekey: 'Analytics',
      urlkey: '/analytics',
      icon: 'fas fa-tachometer',
      allowed_roles:['admin','sysadmin'],
      children:[
        {
          namekey: 'Dashboard',
          urlkey: '/analytics/dashboard',
          icon: 'fas fa-tachometer',
          allowed_roles:['admin','sysadmin']
        },
        {
          namekey: 'Chart Builder',
          urlkey: '/analytics/chartbuilder',
          icon: 'fas fa-tachometer',
          allowed_roles:['admin','sysadmin']
        }
      ]
    },
    {
      title: true,
      namekey: 'Content management',
      wrapper: {
        element: '',
        attributes: {}
      },
      class: ''
    },
    {
      namekey: 'Media management',
      urlkey: '/data/media',
      icon: 'far fa-file-image',
      allowed_roles:['admin','sysadmin','business_admin'],
      children:[
        {
          namekey: 'Galleries',
          urlkey: '/data/media/galleries',
          icon: 'far fa-images',
          allowed_roles:['admin','sysadmin','business_admin']
        },
        {
          namekey: 'Media library',
          urlkey: '/data/media/images',
          icon: 'far fa-image',
          allowed_roles:['admin','sysadmin','business_admin']
        }
      ]
    },
    {
      namekey: 'Data management',
      urlkey: '/data/media',
      icon: 'far fa-database',
      allowed_roles:['admin','sysadmin'],
      children:[
        {
          namekey: 'Section templates',
          urlkey: '/data/section_templates',
          icon: 'fas fa-clone',
          allowed_roles:['admin','sysadmin']
        },
        {
          namekey: 'Attributes',
          urlkey: '/data/attributes',
          icon: 'fas fa-list',
          allowed_roles:['admin','sysadmin']
        }
      ]
    },
    {
      namekey: 'Categories',
      urlkey: '/data/categories',
      icon: 'fas fa-sitemap',
      allowed_roles:['admin','sysadmin']
    },
    {
      namekey: 'Places',
      urlkey: '/data/places',
      icon: 'far fa-map-marker-alt',
      allowed_roles:['admin','sysadmin','business_admin'],
      children:[
        {
          namekey: 'Published',
          urlkey: '/data/places/published',
          icon: 'fal fa-eye',
          allowed_roles:['admin','sysadmin','business_admin']
        },
        {
          namekey: 'Draft',
          urlkey: '/data/places/draft',
          icon: 'fal fa-eye-slash',
          allowed_roles:['admin','sysadmin','business_admin']
        }
      ]
    },
    {
      namekey: 'Events',
      urlkey: '/data/events',
      icon: 'fal fa-calendar-alt',
      allowed_roles:['admin','sysadmin','business_admin'],
      children:[
        {
          namekey: 'Published',
          urlkey: '/data/events/published',
          icon: 'fal fa-eye',
          allowed_roles:['admin','sysadmin','business_admin']
        },
        {
          namekey: 'Draft',
          urlkey: '/data/events/draft',
          icon: 'fal fa-eye-slash',
          allowed_roles:['admin','sysadmin','business_admin']
        }
      ]
    },
    {
      title: true,
      namekey: 'User accounts',
      wrapper: {
        element: '',
        attributes: {}
      },
      class: '',
      allowed_roles:['admin','sysadmin']
    },
    {
      namekey: 'navigation_users',
      urlkey: '/account/users',
      icon: 'fas fa-users',
      allowed_roles:['admin','sysadmin']
    },
    {
      namekey: 'Roles',
      urlkey: '/account/roles',
      icon: 'fas fa-user-tag',
      allowed_roles:['admin','sysadmin']
    },
    {
      title: true,
      namekey: 'System',
      icon: 'fas fa-rocket',
      wrapper: {
        element: '',
        attributes: {}
      },
      class: ''
    },

    {
      namekey: 'Client Applications',
      urlkey: '/system/client-apps',
      icon: 'fas fa-rocket',
      allowed_roles:['admin','sysadmin']
    },
    {
      namekey: 'Notifications',
      icon: 'fas fa-bell',
      urlkey:'/system',
      allowed_roles:['admin','sysadmin','business_admin'],
      children:[
        {
          namekey: 'Provider Apps',
          urlkey: '/system/notification-providers',
          icon: 'fas fa-screwdriver',
          allowed_roles:['admin','sysadmin']
        },
        {
          namekey: 'Devices',
          urlkey: '/system/devices',
          icon: 'fas fa-mobile-alt',
          allowed_roles:['admin','sysadmin']
        },
        {
          namekey: 'Notifications',
          urlkey: '/system/notifications',
          icon: 'fas fa-bell',
          allowed_roles:['admin','sysadmin','business_admin']
        }
      ]
    }

  ]
};
