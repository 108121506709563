import { combineReducers } from 'redux';

import * as authReducers from './auth';
import * as httpReducers from './http';
import * as layoutReducers from './layout';
import * as settingsReducers from './settings';
import { localizeReducer } from 'react-localize-redux';

const rootReducer = combineReducers({
  ...authReducers,
  ...httpReducers,
  ...layoutReducers,
  ...settingsReducers,
  localize: localizeReducer
});

export default rootReducer;
