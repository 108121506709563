/**
ActionButton
Component button with loader
@functions:ActionButton
*/

// Core react dependencies
import React from 'react';
import PropTypes from 'prop-types';

//UI components
import {
  Button
} from 'reactstrap';

ActionButtonComponent.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    size: PropTypes.string,
    block: PropTypes.bool,
    color: PropTypes.string,
    children: PropTypes.oneOfType([
          PropTypes.object,
          PropTypes.array
    ]),
}

ActionButtonComponent.defaultProps = {
  disabled: false,
  loading: false,
  size: 'lg',
  block: true,
  color: 'primary'
}
function ActionButtonComponent(props) {
  const {className, label, disabled, loading, onClick, size, block, color, children} = props;
  return(
      <Button type="submit"
         className={className}
         color={color}
         block={block}
         size={size}
         disabled={disabled || loading}
         onClick={onClick}
         >
        {loading &&
          <span><i className="fa fa-spinner fa-spin"></i>&nbsp;{label}</span>
        }
        {!loading && children &&
          children
        }
        {!loading && !children && label &&
          label
        }
      </Button>
  );
}


export {ActionButtonComponent as ActionButton};
