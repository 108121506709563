import React from 'react';

const Dashboard = React.lazy(() => import('./_pages/Dashboard'));
const Page403 = React.lazy(() => import('./_pages/Page403'));

const EditProfile = React.lazy(() => import('./_pages/Auth/EditProfile'));

//Statistics

const Statistics = React.lazy(() => import('./_pages/Analytics/Statistics'));
const ChartBuilder = React.lazy(() => import('./_pages/Analytics/ChartBuilder'));

/** Account module **/

// Users
const ListUser = React.lazy(() => import('./_pages/Account/User/ListUser'));
const EditUser = React.lazy(() => import('./_pages/Account/User/EditUser'));
const AddUser = React.lazy(() => import('./_pages/Account/User/AddUser'));
const ViewUser = React.lazy(() => import('./_pages/Account/User/ViewUser'));


// Roles
const ListRole = React.lazy(() => import('./_pages/Account/Role/ListRole'));
const RoleForm = React.lazy(() => import('./_pages/Account/Role/RoleForm'));


/** System module **/

// Client apps
const ListClientApp = React.lazy(() => import('./_pages/System/ClientApp/ListClientApp'));
const EditClientApp = React.lazy(() => import('./_pages/System/ClientApp/EditClientApp'));

// Notification providers
const ListNotificationProvider = React.lazy(() => import('./_pages/System/NotificationProvider/ListNotificationProvider'));
const EditNotificationProvider = React.lazy(() => import('./_pages/System/NotificationProvider/EditNotificationProvider'));


// Devices
const ListDevice = React.lazy(() => import('./_pages/System/Device/ListDevice'));


// Notifications
const ListNotification = React.lazy(() => import('./_pages/System/Notification/ListNotification'));
const ViewNotification = React.lazy(() => import('./_pages/System/Notification/ViewNotification'));
const SendNotification = React.lazy(() => import('./_pages/System/Notification/SendNotification'));
//const SendNotificationToFollowers = React.lazy(() => import('./_pages/System/Notification/SendNotificationToFollowers'));

/** Data module **/
//Categories
const ListCategory = React.lazy(() => import('./_pages/Data/Category/ListCategory'));
const CategoryForm = React.lazy(() => import('./_pages/Data/Category/CategoryForm'));

//Attributes
const ListAttribute = React.lazy(() => import('./_pages/Data/Attribute/ListAttribute'));
const AttributeForm = React.lazy(() => import('./_pages/Data/Attribute/AttributeForm'));


//Section templates
const ListSectionTemplate = React.lazy(() => import('./_pages/Data/SectionTemplate/ListSectionTemplate'));

//Item
const ListItem = React.lazy(() => import('./_pages/Data/Item/ListItem'));
const ViewItem = React.lazy(() => import('./_pages/Data/Item/ViewItem'));
const EditItem = React.lazy(() => import('./_pages/Data/Item/EditItem'));
const AddItem = React.lazy(() => import('./_pages/Data/Item/AddItem'));

//Media
const ListImage = React.lazy(() => import('./_pages/Data/Media/ListImage'));
const ListGallery = React.lazy(() => import('./_pages/Data/Media/ListGallery'));

const routes = [
  { path: '', name: 'Dashboard', component: Dashboard},
  { path: 'analytics/dashboard', name: 'Statistics', component: Statistics},
  { path: 'analytics/chartbuilder', name: 'ChartBuilder', component: ChartBuilder},
  { path: 'system/client-apps', name: 'List Client app', component: ListClientApp, allowed_roles:['admin','sysadmin'] },
  { path: 'system/client-apps/:id', name: 'Edit client app', component: EditClientApp,  allowed_roles:['admin','sysadmin'] },
  { path: 'system/notification-providers', name: 'List Notification provider', component: ListNotificationProvider, allowed_roles:['admin','sysadmin'] },
  { path: 'system/notification-providers/edit/:id', name: 'Edit Notification provider', component: EditNotificationProvider, allowed_roles:['admin','sysadmin'] },
  { path: 'system/devices', name: 'List Device', component: ListDevice, allowed_roles:['admin','sysadmin'] },
  { path: 'system/notifications', name: 'List Notification', component: ListNotification, allowed_roles:['admin','sysadmin','business_admin'] },
  { path: 'system/notifications/send', name: 'Send Notification', component: SendNotification, allowed_roles:['admin','sysadmin'] },
//  { path: 'system/notifications/send-to-followers/item/:id', name: 'SendNotificationToFollowers', component: SendNotificationToFollowers, allowed_roles:['admin','sysadmin'] },
  { path: 'system/notifications/:id', name: 'View Notification', component: ViewNotification, allowed_roles:['admin','sysadmin','business_admin'] },
  { path: 'account/users', name: 'List user', component: ListUser, allowed_roles:['admin','sysadmin'] },
  { path: 'account/users/edit/:id', name: 'Edit user', component: EditUser,  allowed_roles:['admin','sysadmin'] },
  { path: 'account/users/add', name: 'Add user', component: AddUser,  allowed_roles:['admin','sysadmin'] },
  { path: 'account/users/:id', name: 'View user', component: ViewUser,  allowed_roles:['admin','sysadmin'] },
  { path: 'account/roles', name: 'List role', component: ListRole, allowed_roles:['admin','sysadmin'] },
  { path: 'account/roles/add', name: 'Add role', component: RoleForm, allowed_roles:['admin','sysadmin'] },
  { path: 'account/roles/:id', name: 'Edit role', component: RoleForm, allowed_roles:['admin','sysadmin'] },
  { path: 'data/categories', name: 'List category', component: ListCategory,  allowed_roles:['admin','sysadmin'] },
  { path: 'data/categories/edit/:id', name: 'Edit category', component: CategoryForm,  allowed_roles:['admin','sysadmin'] },
  { path: 'data/categories/add', name: 'Add category', component: CategoryForm,  allowed_roles:['admin','sysadmin'] },
  { path: 'data/events/draft', name: 'List item', component: ListItem,  allowed_roles:['admin','sysadmin','business_admin'] },
  { path: 'data/events/published', name: 'List item', component: ListItem,  allowed_roles:['admin','sysadmin','business_admin'] },
  { path: 'data/places/draft', name: 'List item', component: ListItem,  allowed_roles:['admin','sysadmin','business_admin'] },
  { path: 'data/places/published', name: 'List item', component: ListItem,  allowed_roles:['admin','sysadmin','business_admin'] },
  { path: 'data/places/add', name: 'Add item', component: AddItem,  allowed_roles:['admin','sysadmin','business_admin'] },
  { path: 'data/events/add', name: 'Add item', component: AddItem,  allowed_roles:['admin','sysadmin','business_admin'] },
  { path: 'data/places/edit/:id/parent/:parent_id', name: 'Edit item', component: EditItem,  allowed_roles:['admin','sysadmin','business_admin'] },
  { path: 'data/events/edit/:id/parent/:parent_id', name: 'Edit item', component: EditItem,  allowed_roles:['admin','sysadmin','business_admin'] },
  { path: 'data/places/edit/:id', name: 'Edit item', component: EditItem,  allowed_roles:['admin','sysadmin','business_admin'] },
  { path: 'data/events/edit/:id', name: 'Edit item', component: EditItem,  allowed_roles:['admin','sysadmin','business_admin'] },
  { path: 'data/items/:id', name: 'View item', component: ViewItem,  allowed_roles:['admin','sysadmin'] },
  { path: 'data/attributes', name: 'List attribute', component: ListAttribute,  allowed_roles:['admin','sysadmin'] },
  { path: 'data/attributes/add', name: 'Add attribute', component: AttributeForm, allowed_roles:['admin','sysadmin'] },
  { path: 'data/attributes/:id', name: 'Edit attribute', component: AttributeForm, allowed_roles:['admin','sysadmin'] },
  { path: 'data/section_templates', name: 'List section template', component: ListSectionTemplate,  allowed_roles:['admin','sysadmin'] },
  { path: 'access_denied', name: 'Access denied', component: Page403},
  { path: 'auth/edit-profile', name: 'EditProfile', component: EditProfile},
  { path: 'data/media/images', name: 'ListImage', component: ListImage,  allowed_roles:['admin','sysadmin','business_admin'] },
  { path: 'data/media/galleries', name: 'ListGallery', component: ListGallery,  allowed_roles:['admin','sysadmin','business_admin'] }
];

export default routes;
