/**
InputDateField
Input date picker component with field error message
@functions:InputDateFieldComponent
*/
// Core react dependencies
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

/** UI components **/
import { Col, FormGroup, Label } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { enUS, fr } from 'date-fns/locale';

// Custom modules/components
import { FormFieldErrorText } from './FormFieldErrorText';

// Translation
import { getActiveLanguage } from 'react-localize-redux';

//Local lib
import { fallbackLocale } from '../../_config'
registerLocale('fr', fr);
registerLocale('en', enUS);
setDefaultLocale(fallbackLocale);

class InputDateFieldComponent extends React.Component {
  /**
  * To setup the inital state
  * @param {Object} props
  */
  static propTypes = {
      name: PropTypes.string,
      id: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string,
      onChange: PropTypes.func,
      errorMessages: PropTypes.array,
      inline: PropTypes.bool,
      required: PropTypes.bool,
      showTimeSelect: PropTypes.bool
  }

  static defaultProps = {
    errorMessages: [],
    inline: false,
    required: false,
    showTimeSelect:false
  }

  render() {
  const {
    name,
    id,
    label,
    value,
    onChange,
    errorMessages,
    inline,
    disabled,
    required,
    activeLanguage,
    showTimeSelect
  } = this.props;
  let defaultValue = new Date();
  if(value !== '')
  defaultValue = new Date(value.replace(/ /g,"T"));
  return (
    <div  className="mb-3">
      {inline &&
        <FormGroup row>
          <Col md="3">
            <Label htmlFor={name}>
              {label}
              {required &&
                <span className="text-danger">*</span>
              }
            </Label>
          </Col>
          <Col xs="12" md="9">
            <DatePicker
              selected={defaultValue}
              onChange={(val)=>onChange({
                  target:{
                    name:name,
                    value:val.toISOString()
                  }
              })}
              dateFormat={showTimeSelect?'dd/MM/yyyy HH:mm':'dd/MM/yyyy'}
              disabled={disabled}
              locale={activeLanguage}
              showTimeSelect={showTimeSelect}
              timeFormat="HH:mm"
              timeIntervals={15}
          />
            {errorMessages && errorMessages.length > 0 &&
              <FormFieldErrorText messages={errorMessages} field={id}  />
            }
          </Col>
        </FormGroup>
      }
      { !inline &&
        <FormGroup>
          <Label htmlFor={name}>
            {label}
            {required &&
              <span className="text-danger">*</span>
            }
          </Label>
          <DatePicker
            selected={defaultValue}
            onChange={(val)=>onChange({
                target:{
                  name:name,
                  value:val.toISOString()
                }
            })}
            dateFormat={showTimeSelect?'dd/MM/yyyy HH:mm':'dd/MM/yyyy'}
            disabled={disabled}
            locale={activeLanguage}
            showTimeSelect={showTimeSelect}
            timeFormat="HH:mm"
            timeIntervals={15}
          />
        {errorMessages && errorMessages.length > 0 &&
            <FormFieldErrorText messages={errorMessages} field={id}  />
          }
        </FormGroup>
      }
    </div>
  );
}
}

const mapStateToProps = state => (
  {
    activeLanguage: getActiveLanguage(state.localize).code
  }
);

InputDateFieldComponent = connect(mapStateToProps)(InputDateFieldComponent)
export {InputDateFieldComponent as InputDateFieldWithLabel};
