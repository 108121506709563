
import {BLOCK_ACCESS_RULES} from '../_config/access';


/**
* to check the access for the given block
* @returns  {Object} HTML element
*/
export function isBlockAccessDenied(blockName) {
  const user = JSON.parse(localStorage.getItem('user'));
  let allowedRoles='';
  BLOCK_ACCESS_RULES.filter(rule => rule.block_name === blockName).map((rule)=>{
    allowedRoles = rule.allowed_roles;
    return true;
  })
  if(!user)
   return true;
  if(allowedRoles !== ''){
    if(allowedRoles.includes(user.role.slug) && (user.role.backoffice_access === 1) && user.activated ===1)
      return false;
    else
      return true;
  }
  else{
    return false;
  }
}


/**
* to check the access for the given block
* @returns  {Object} HTML element
*/
export function isBlockAccessDeniedToUser(blockName, user) {
  let allowedRoles='';
  BLOCK_ACCESS_RULES.filter(rule => rule.block_name === blockName).map((rule)=>{
    allowedRoles = rule.allowed_roles;
    return true;
  })
  if(!user)
   return false;
  if(allowedRoles !== ''){
    if(allowedRoles.includes(user.role.slug) && (user.role.backoffice_access === 1) && user.activated ===1)
      return false;
    else
      return true;
  }
  else{
    return false;
  }
}

/**
* Is the logged in user is admin
*/
export function isLoggedInUserAdmin() {
  const user = JSON.parse(localStorage.getItem('user'));
  return (user.role.slug === 'sysadmin' ||user.role.slug === 'admin');
}
