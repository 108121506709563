/**
Constants to manage the translation
*/

export const availableLanguages = [
  { code: 'fr', name: 'French', sort_order:0},
  { code: 'en', name: 'English', sort_order:1 }
];

export const fallbackLocale = process.env.REACT_APP_DEFAULT_LOCALE;
