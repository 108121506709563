/**
InputMultiSelectWithLabel
Input select field Component with field error message
@functions:InputMultiSelectWithLabelComponent
*/
// Core react dependencies
import React from 'react';
import PropTypes from 'prop-types';

/** UI components **/
import { Col, FormGroup, Label } from 'reactstrap';
import Select from 'react-select';
import 'react-select/dist/react-select.min.css';

// Custom modules/components
import { FormFieldErrorText } from './FormFieldErrorText';

InputMultiSelectWithLabelComponent.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.array,
    onChange: PropTypes.func,
    errorMessages: PropTypes.array,
    inline: PropTypes.bool
}

InputMultiSelectWithLabelComponent.defaultProps = {
  errorMessages: [],
  inline: false
}
function InputMultiSelectWithLabelComponent(props) {
  const {
    name,
    id,
    label,
    value,
    options,
    onChange,
    errorMessages,
    inline
  } = props;
  return (

    <div className="mb-3">
      {inline &&
        <FormGroup row>
          <Col md="3">
            <Label htmlFor={name}>{label}</Label>
          </Col>
          <Col xs="12" md="9">
            <Select
              id={id}
              name={name}
              value={value}
              options={options}
              onChange={onChange}
              multi
            />
            {errorMessages && errorMessages.length > 0 &&
              <FormFieldErrorText messages={errorMessages} field={id}  />
            }
          </Col>
        </FormGroup>
      }
      {!inline &&
        <FormGroup>
          <Label htmlFor={name}>{label}</Label>
          <Select
            className="Select-form-control"
            id={id}
            name={name}
            value={value}
            options={options}
            onChange={onChange}
            multi
          />
          {errorMessages && errorMessages.length > 0 &&
            <FormFieldErrorText messages={errorMessages} field={id}  />
          }
        </FormGroup>
      }


    </div>
  );
}

export {InputMultiSelectWithLabelComponent as InputMultiSelectWithLabel};
