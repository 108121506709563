/**
layoutActions
To dispatch the layout actions to handle layout actions
@functions:setBreadcrumb
*/


export const layoutActions = {
    initBreadcrumb,
    setBreadcrumb,
    showSectionAsideBar,
    hideSectionAsideBar,
    setSectionAsideBarData,
    showLoader,
    hideLoader
};
/**
* initBreadcrumb
* To initialize the breadcrumbs
*/
function initBreadcrumb() {
  return { type: 'SET_BREADCRUMB', payload:[] }
}

/**
* setBreadcrumb
* To set the breadcrumbs
*/
function setBreadcrumb(breadcrumbs) {
 return { type: 'SET_BREADCRUMB', payload:breadcrumbs }
}


/**
* showSectionAsideBar
* To show the Asidebar
*/
function showSectionAsideBar() {
  return { type: 'SHOW_ASIDEBAR'}
}

/**
* hideSectionAsideBar
* To hide the Asidebar
*/
function hideSectionAsideBar() {
 return { type: 'HIDE_ASIDEBAR'}
}

/**
* setSectionAsideBarData
* To hide the Asidebar
*/

function setSectionAsideBarData(data) {
 return { type: 'SET_SECTION_ASIDEBLOCK_DATA',payload:data}
}


/**
* showLoader
* To show the page loader
*/
function showLoader() {
 return { type: 'SHOW_PAGE_LOADER', payload:true }
}


/**
* hideLoader
* To hide the page loader
*/
function hideLoader() {
 return { type: 'HIDE_PAGE_LOADER', payload:false }
}
