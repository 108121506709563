/**
urlConfig: to define the url Constants
*/

export const urlConfig = {
    BASE_URL:'',
    API_URL: process.env.REACT_APP_API_URL,
    CUBE_API_URL:process.env.REACT_APP_CUBE_API_URL,
    LOCAL_IMAGE_PATH: process.env.REACT_APP_LOCAL_IMAGE_PATH,
    REMOTE_IMAGE_PATH:'',
    MAP_URL:'https://maps.googleapis.com/maps/api/js?key=AIzaSyBmjyFpu6yOlFXUYMsYsK6NqLtobHeqQEw',
    WEBSITE_URL:process.env.REACT_APP_WEBSITE_URL
};
