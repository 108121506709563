import { getActiveLanguage } from 'react-localize-redux';

import { authActions } from '../_services/actions';
import { store, history } from '../_helpers';
import { urlConfig, fallbackLocale } from '../_config';

export function printString(str){
  if(str !== undefined && str !== null)
  return str;
  else
  return '';
}

// HTTP functions
export function onHttpFailure(errorResponse, dispatch = undefined, onFailure = undefined){
  // eslint-disable-next-line
  /*if(errorResponse == 'TypeError: Failed to fetch'){
    dispatch(authActions.logout);
  }
  else*/
  if(errorResponse.json && errorResponse.json.status === 404){
      const state = store.getState();
      let locale = fallbackLocale;
      if(getActiveLanguage(state.localize))
        locale = getActiveLanguage(state.localize).code;
      history.push(`${urlConfig.BASE_URL}/${locale}/404`);
  }
  if(errorResponse.json && errorResponse.json.status === 403){
      const state = store.getState();
      let locale = fallbackLocale;
      if(getActiveLanguage(state.localize))
        locale = getActiveLanguage(state.localize).code;
      history.push(`${urlConfig.BASE_URL}/${locale}/access_denied`);
  }
  else if(errorResponse.json && errorResponse.json.code === 4010){
    dispatch(authActions.logout);
  }
  else if(onFailure !== undefined){
    onFailure(errorResponse);
  }
}

// Remote pagination, sorting, searching
export function getTableDataQueryParams(sortParams={}, pageParams={}, searchParams={}){
  let searchQueryParams = '';
  if(searchParams && searchParams.query && searchParams.query !== ''){
    searchQueryParams += (searchParams.query)?'search='+searchParams.query+'&':'';
    if(searchParams.searchFields && searchParams.searchFields !== '' )
      searchQueryParams += 'searchFields='+searchParams.searchFields+'&';
  }

  let sortQueryParams = '';
  if(sortParams && sortParams.name){
    let orderBy = sortParams.name;
    let sortBy = sortParams.order;
    sortQueryParams += (orderBy)?'orderBy='+orderBy+'&':'';
    sortQueryParams += (sortBy)?'sortBy='+sortBy+'&':'';
  }

  let pageQueryParams = '';
  if(pageParams && pageParams.perPage){
    let perPage = pageParams.perPage;
    let page = pageParams.currentPage;
    pageQueryParams += (perPage)?'per_page='+perPage+'&':'';
    pageQueryParams += (page)?'page='+page+'&':'';
  }

  return searchQueryParams+sortQueryParams+pageQueryParams;
}


export function convertBase64ToImage(ImageURL){
    // Split the base64 string in data and contentType
    var block = ImageURL.split(";");
    // Get the content type of the image
    var contentType = block[0].split(":")[1];// In this case "image/gif"
    // get the real base64 content of the file
    var realData = block[1].split(",")[1];
    return b64toBlob(realData, contentType);
  }

 function b64toBlob(b64Data, contentType, sliceSize){
      contentType = contentType || '';
      sliceSize = sliceSize || 512;
      var byteCharacters = atob(b64Data);
      var byteArrays = [];

      for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          var slice = byteCharacters.slice(offset, offset + sliceSize);
          var byteNumbers = new Array(slice.length);
          for (var i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
          }
          var byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
      }

    var blob = new Blob(byteArrays, {type: contentType});
    return blob;
}
// Returns if a value is a string
function isString (value) {
  return typeof value === 'string' || value instanceof String;
}

export function removeAccent(str){
  var diacritics = [
    {char: 'A', base: /[\300-\306]/g},
    {char: 'a', base: /[\340-\346]/g},
    {char: 'E', base: /[\310-\313]/g},
    {char: 'e', base: /[\350-\353]/g},
    {char: 'I', base: /[\314-\317]/g},
    {char: 'i', base: /[\354-\357]/g},
    {char: 'O', base: /[\322-\330]/g},
    {char: 'o', base: /[\362-\370]/g},
    {char: 'U', base: /[\331-\334]/g},
    {char: 'u', base: /[\371-\374]/g},
    {char: 'N', base: /[\321]/g},
    {char: 'n', base: /[\361]/g},
    {char: 'C', base: /[\307]/g},
    {char: 'c', base: /[\347]/g}
  ]
  if(isString(str)){
    diacritics.forEach(function(letter){
      str = str.replace(letter.base, letter.char);
    });
    return str.toLowerCase();
  }
  else
    return str;
};

export function formatSlug(str){

  var diacritics = [
    {char: 'A', base: /[\300-\306]/g},
    {char: 'a', base: /[\340-\346]/g},
    {char: 'E', base: /[\310-\313]/g},
    {char: 'e', base: /[\350-\353]/g},
    {char: 'I', base: /[\314-\317]/g},
    {char: 'i', base: /[\354-\357]/g},
    {char: 'O', base: /[\322-\330]/g},
    {char: 'o', base: /[\362-\370]/g},
    {char: 'U', base: /[\331-\334]/g},
    {char: 'u', base: /[\371-\374]/g},
    {char: 'N', base: /[\321]/g},
    {char: 'n', base: /[\361]/g},
    {char: 'C', base: /[\307]/g},
    {char: 'c', base: /[\347]/g}
  ]

  diacritics.forEach(function(letter){
    str = str.replace(letter.base, letter.char);
  });
  let randomString = Math.random().toString(36).substr(2, 4);
  return str.toLowerCase().replace(/[^A-Z0-9]/ig, "_")+"_"+randomString;
};
