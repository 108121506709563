/**
DefaultHeaderDropdown
The dropdown of the header in the container
*/
//Core react libs
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

//UI components
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import { getTranslate, getActiveLanguage, getLanguages, setActiveLanguage  } from 'react-localize-redux';
//UI components
import { Img } from '../../_components/ui';

//Custom libs
import { authActions } from '../../_services/actions';
import { history } from '../../_helpers';
import { urlConfig } from '../../_config';
import { translateActions } from '../../_services/actions';

const propTypes = {
  Account: PropTypes.bool,
  languageDropdown: PropTypes.bool,
};
const defaultProps = {
  Account: false,
  languageDropdown: false
};

class DefaultHeaderDropdown extends Component {

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
    };
    //to setup the translation
    this.props.addTranslationForPage(
      this.props.languages,
      'Layout',
      'Languages'
    );

    this.props.addTranslationForPage(
      this.props.languages,
      'Layout',
      'Account'
      );
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }


  dropAccount() {
    const { logout, translate, user, activeLanguage } = this.props;
    return (
      <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle nav>
          <Img
           src={`${urlConfig.REMOTE_IMAGE_PATH}${user.avatar}`}
           className="img-avatar"
           alt={user.email}
          />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem header tag="div" className="text-center"><strong>{translate('Account')}</strong></DropdownItem>
          <DropdownItem href={`${urlConfig.BASE_URL}/${activeLanguage}/auth/edit-profile`}><i className="far fa-user-edit"></i> {translate('Profile')}</DropdownItem>
          {/* <DropdownItem><i className="far fa-user-cog"></i> {translate('Settings')}</DropdownItem> */}
          <DropdownItem onClick={logout}><i className="far fa-sign-out"></i> {translate('Logout')}</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }
  dropLanguages() {
    const {languages, activeLanguage, translate } = this.props;

    const languageOptions = [];
    const handleChange = (e,{code}) =>{
      let origPath = this.props.location.pathname+this.props.location.hash;
      history.push(origPath.replace('/'+activeLanguage,'/'+code));
    }
    languageOptions.push(  <DropdownItem key="title" header tag="div" className="text-center"><strong>{translate('Languages')}</strong></DropdownItem>);
    languages.map(function(language){
      languageOptions.push(<DropdownItem key={language.code} active={activeLanguage === language.code}  onClick={(e) => handleChange(e, language)}>{translate(language.code)}</DropdownItem>);
      return true;
    });
    return (
      <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggle} >
        <DropdownToggle nav caret>
          <span><i className="far fa-globe-africa"></i></span>
        </DropdownToggle>
        <DropdownMenu right>
          {languageOptions}
        </DropdownMenu>
      </Dropdown>
    );
  }

  componentDidUpdate(prevProps) {
    const prevLangCode = prevProps.activeLanguage && prevProps.activeLanguage;
    const curLangCode = this.props.activeLanguage && this.props.activeLanguage;
    const hasLanguageChanged = prevLangCode !== curLangCode;
    if(hasLanguageChanged){
      // to change the lanugage code in the URL
      let currentLocation = history.location;
      let currentURL = currentLocation.pathname;
      var newURL = currentURL.replace(`/${prevLangCode}`, `/${curLangCode}`);
      currentLocation.pathname = newURL;
      history.replace(currentLocation);
    }
  }
  render() {
    const { account, languageDropdown } = this.props;
    return (
          account ? this.dropAccount():
          languageDropdown ? this.dropLanguages()
          : null
    );
  }
}

DefaultHeaderDropdown.propTypes = propTypes;
DefaultHeaderDropdown.defaultProps = defaultProps;


const mapDispatchToProps = dispatch => ({
  logout: bindActionCreators(authActions.logout, dispatch),
  setActiveLanguage: bindActionCreators(setActiveLanguage, dispatch),
  addTranslationForPage: bindActionCreators(translateActions.addTranslationForPage, dispatch),
});

const mapStateToProps = state => (
  {
    user: state.authentication.user,
    activeLanguage: getActiveLanguage(state.localize).code,
    languages: getLanguages(state.localize),
    translate: getTranslate(state.localize),
  }
);
DefaultHeaderDropdown = connect(mapStateToProps, mapDispatchToProps)(DefaultHeaderDropdown)
export {DefaultHeaderDropdown};
